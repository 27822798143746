.formGroup input {
  border: 1px solid #ffffff;
  border-radius: 28px;
  padding: 16px 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #f5f5f5;
  background-color: transparent;
  box-sizing: border-box;
  outline: none;
}
.formGroup {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.w50 {
  width: 50%;
}
.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  padding: 10px 20px;
  background-color: #59b4e0;
  border-radius: 20px;
  display: flex;
  gap: 10px;
}

.tag div {
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: black;
}

.tag h3 {
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 856px) {
  .w50 {
    width: 100%;
  }
}