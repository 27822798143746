.main {
  margin-top: 20px;
  overflow: auto;
}

.main h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #59b4e0;
  margin-top: 8px;
  margin-bottom: 16px;
}

.featured {
  margin-bottom: 22px;
}

.cont h1 {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  margin-top: 15px;
  margin-bottom: 33px;
  text-align: center;
}

.formGroup {
  margin-bottom: 25px;
}

.tags input,
.formGroup input,
.formGroup select,
.formGroup textarea {
  border: 1px solid #ffffff;
  border-radius: 28px;
  padding: 16px 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #f5f5f5;
  background-color: transparent;
  box-sizing: border-box;
  outline: none;
  resize: none;
}

.type1 {
  width: 500px;
}

.type2 {
  display: flex;
  gap: 10px;
}

.type2 input {
  flex: 1;
}

.type2 input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.formGroup select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: URL("../../assets//icon/down-arrow.png") no-repeat right 20px
    center;
}

.formGroup input[type="file"]::-webkit-file-upload-button {
  background-color: transparent;
  outline: none;
  border: none;
  color: #ffffff;
}

.formGroup select option {
  background-color: #1e1e1e;
}

.formGroup textarea {
  resize: none;
  width: 100%;
  height: 165px;
}

.tags h2 {
  margin-bottom: 10px;
}

.submit input {
  background: #59b4e0;
  border-radius: 28px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 18px;
  outline: none;
  border: none;
  width: 100%;
  padding: 18px 0;
  cursor: pointer;
}

.successMessage {
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.successMessage h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

.upcoming {
  margin-right: 10px;
}

@media screen and (max-width: 856px) {
  .featured,
  .upcoming {
    margin-left: 14px;
    margin-right: 14px;
  }
}
