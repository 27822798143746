.overview h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #59b4e0;
  margin-bottom: 12px;
}

.unpack {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media screen and (max-width: 856px) {
  .overview{
    padding: 0 14px;
  }
}