@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Open Sans", sans-serif;
  background: #1e1e1e;
  color: white;
}