.card {
  background: #383838;
  border-radius: 12px;
  width: fit-content;
}

.card h4 {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #ffc700;
  margin: 6px 0;
  margin-left: 12px;
}

.imageContainer {
  width: 375px;
  height: 161px;
  display: flex;
  overflow: hidden;
}

.imageWrapper {
  border-radius: 12px;
  overflow: hidden;
  flex: 1;
}

.imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.det {
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 14px;
  padding-bottom: 20px;
}

.left h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 8px;
}

.left h5 {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.left > div {
  display: flex;
  gap: 12px;
  align-items: center;
}

.left > div > div {
  display: flex;
  align-items: center;
  gap: 6px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.right .edit {
  padding: 10px 34px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  background: #59b4e0;
  border-radius: 25px;
  border: none;
}

.right .delete {
  text-decoration: none;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #59b4e0;
}
