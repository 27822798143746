.speakersNav {
  background: #383838;
  margin-top: 3px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 12px;
  display: flex;
}

.speakers {
  flex: 1;
  background: #383838;
  margin-right: 10px;
  border-radius: 12px;
  margin-bottom: 14px;
  overflow-x: scroll;
}

.header {
  margin: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #59b4e0;
}

.body {
  margin: 0 24px;
}

.link {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #d9d9d9;
  text-decoration: none;
  padding: 24px 12px;
  display: block;
  border-bottom: transparent 3px solid;
}

.active,
.link:hover {
  border-color: #59b4e0;
  color: #59b4e0;
}

@media screen and (max-width: 856px) {
  .speakers,
  .speakersNav{
    margin-left: 14px;
    margin-right: 14px;
    overflow-x: scroll;
  }
}