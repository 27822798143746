.t1 {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-right: 10px;
}
.t1 h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #59b4e0;
  margin-bottom: 12px;
}

.v div {
  background: #383838;
  border-radius: 12px;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v img {
  margin-bottom: 20px;
  align-self: center;
}

.v p {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 8px;
}

.v button {
  margin-top: 12px;
  background: #59b4e0;
  border-radius: 25px;
  width: 250px;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.v,
.w {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.w div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.t2 {
  margin-top: 20px;
  margin-right: 10px;
}

.videos {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
@media screen and (max-width: 856px) {
  .t1,.t2{
    margin-left: 14px;
    margin-right: 14px;
  }
}