.cont {
  background: #383838;
  border-radius: 12px;
  width: 280px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.img {
  height: 52px;
  width: 52px;
  border-radius: 50%;
  margin-left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 23px;
  margin-bottom: 23px;
}

.side h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.side p {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 1px;
}
@media screen and (max-width: 856px) {
  .cont{
    width: 199px;
  }
  .side h4{
    font-size: 10px;
  }
  .side p {
    font-size: 18px;
    margin-top: 2px;
  }
}

@media screen and (max-width: 436px) {
  .cont{
    width: 90vw;
  }
}