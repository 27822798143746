.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.header h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #59b4e0;
  margin-top: 8px;
  margin-bottom: 16px;
}

.header button {
  background: #59b4e0;
  border-radius: 25px;
  border: none;
  padding: 10px 26px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  color: #000;
}
