.fileUp label {
  color: #59b4e0;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.fileUp {
  margin-bottom: 25px;
}

.fileUp input {
  display: none;
}

.fileUp span{
  margin-left: 10px;
}

.b {
  flex: 1;
  overflow-y: scroll;
  animation: slide-down 300ms ease-out forwards;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #fff #1e1e1e; /* For Firefox */
} 

.b::-webkit-scrollbar {
  width: 8px;
}

.b::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 8px;
}

.b::-webkit-scrollbar-track {
  background-color: #1e1e1e;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.form {
  margin-right: 10px;
  margin-top: 30px;
}

.formGroup {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.w50 {
  width: 50%;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
  border: 1px solid #ffffff;
  border-radius: 28px;
  padding: 16px 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #f5f5f5;
  background-color: transparent;
  box-sizing: border-box;
  outline: none;
  resize: none;
}

.formGroup textarea {
  height: 185px;
}


.formGroup input[list],
.formGroup select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: URL("../../assets/icon/down-arrow.png") no-repeat right 20px
    center;
}

.formGroup datalist option{
  background-color: red;
}

.submit input {
  background: #59b4e0;
  border-radius: 28px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 18px;
  outline: none;
  border: none;
  width: 50%;
  padding: 18px 0;
  cursor: pointer;
}

.formGroup select option {
  background-color: #1e1e1e;
}
@media screen and (max-width: 856px) {
  .form{
    margin-left: 10px;
  }
  .submit input {
    width: 100%;
  }
  .w50{
    width: 100%;
  }
  .b{
    margin-left: 14px;
    margin-right: 14px;
  }
}