.nav {
  width: fit-content;
  height: 100vh;
  background: #383838;
  border-radius: 0px 12px 12px 0px;
  display: flex;
  flex-direction: column;
  position: sticky;
}

.nav > h1 {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #59b4e0;
  margin-top: 34px;
  margin-left: 31px;
  margin-bottom: 34px;
}

.bottom {
  margin-top: auto;
}
.mobileNav{
  display: none;
}

@media screen and (max-width: 856px) {
  .nav {
    display: none;
  }
  .mobileNav {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background: #383838;
    display: flex;
    flex-direction: column;
  }
  .t > h1 {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #59b4e0;
    margin-top: 34px;
    margin-left: 31px;
    margin-bottom: 34px;
  }
  .j{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .t{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .t > img{
    margin-right: 15px;
  }
}
