.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1e1e1e;
  border-radius: 24px;
  padding: 25px;
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  max-height: 800px;
  overflow-y: scroll;
}

.modal::-webkit-scrollbar {
  width: 1px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 8px;
}

.modal::-webkit-scrollbar-track {
  background-color: #1e1e1e;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translate(-50%, -80%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
