.dash {
  display: flex;
  gap: 12px;
  height: 100vh;
  overflow-y: hidden;
}

.f {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
}