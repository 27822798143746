.nav {
  display: flex;
  gap: 24px;
}

.link {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #f5f5f5;
  text-decoration: none;
  /* padding: 24px 12px; */
}

.active,
.link:hover {
  color: #59b4e0;
}

.cont {
  padding: 14px;
  background: #383838;
  border-radius: 12px;
  margin-right: 10px;
}

.t {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding-bottom: 14px;
  border-bottom: solid thin white;
}

.cont {
  flex: 1;
  margin-bottom: 14px;
}
@media screen and (max-width: 856px) {
  .cont{
    margin-left: 14px;
    margin-right: 14px;
  }
}