.users {
  margin-top: 50px;
  margin-right: 10px;
  flex: 1;
  background: #383838;
  border-radius: 12px;
  margin-bottom: 14px;
}

.header {
  margin: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body {
  margin: 0 24px;
}

.header h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #59b4e0;
}
@media screen and (max-width: 856px) {
  .users{
    margin-left: 14px;
    margin-right: 14px;
  }
}