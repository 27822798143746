.cont {
  margin-top: 30px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.cont h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #59b4e0;
  margin-bottom: 12px;
}

.left div {
  background: #383838;
  border-radius: 12px;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left img {
  margin-bottom: 20px;
  align-self: center;
}

.left p {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 8px;
}

.left button {
  margin-top: 12px;
  background: #59b4e0;
  border-radius: 25px;
  width: 250px;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
@media screen and (max-width: 856px) {
  .cont{
    padding: 0 14px;
  }
  .left div {
    padding: 40px;
  }
}

