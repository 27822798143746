.pagination {
  list-style: none;
  display: flex;
  gap: 10px;
  align-items: center;
}

.active {
  padding: 5px 12px;
  color: #1e1e1e;
  background: #59b4e0;
  border-radius: 6px;
}
