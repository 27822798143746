.cont {
  margin-top: 28px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hb{
  display: flex;
  gap: 10px;
  align-items: center;
}
.back{
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.menu{
  display: none;
}
.headingOne {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}
.right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.searchCont {
  position: relative;
}

.searchCont input {
  padding: 10px 14px;
  border: 1.5px solid #ffffff;
  border-radius: 25px;
  background-color: #1e1e1e;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #f5f5f5;
}

.searchCont > .search {
  position: absolute;
  right: 16px;
  top: 12px;
}
.search{
  display: flex;
}

.profile {
  border-radius: 50%;
  background-color: red;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.profile img {
  object-fit: cover;
  align-items: center;
  background-size: contain;
}

@media screen and (max-width: 856px) {
  .cont{
    background-color: rgba(56, 56, 56, 1);
    margin-top: 0;
    padding: 24px 10px;
  }

  .menu{
    display: block;
  }

  .headingOne{
    font-size: 20px;
  }

  .searchCont input{
    display: none;
  }
  
  .searchCont > .search {
    position: initial;
    right: initial;
    top: initial;
  }
  .profile {
    width: 30px;
    height: 30px;
  }
}