.table {
  width: 100%;
  border-collapse: collapse;
}
.table thead {
  text-align: left;
}

.table th,
.table td {
  padding-top: 17px;
  padding-bottom: 17px;
  border-bottom: 1px solid #d9d9d9;
}

.table th {
  font-size: 16px;
  line-height: 24px;
}

.table td {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.table tr {
  border-bottom: 1px solid #d9d9d9;
}
