.container {
  position: absolute;
  padding: 41px;
  border-radius: 24px;
  background: #383838;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.container > form {
  display: flex;
  flex-direction: column;
}

.InputGroup {
  position: relative;
  margin: 20px 0;
}

.InputGroup > label {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  position: absolute;
  top: -8.8px;
  left: 33px;
  outline: none;
  background-color: #383838;
  padding-left: 5px;
  padding-right: 10px;
}

.InputGroup > input {
  border: 1px solid #ffffff;
  border-radius: 25px;
  width: 500px;
  padding: 16px 21px 16px 21px;
  background-color: transparent;
  transition: background-color 0.2s;
  box-shadow: 0 0 0 50px #383838 inset;
  color: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
}

.InputGroup > input:focus {
  outline: none;
}

.InputGroup > input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff !important;
}

.InputGroup > input[type="password"]::-ms-reveal {
  display: none;
}

.img {
  position: absolute;
  right: 27px;
  top: 16px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 24px;
}

.login {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  height: 55px;
  border-radius: 27.5px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
}

.rem {
  margin-bottom: 34px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.rem input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.rem input + label {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 22px;
  color: #59b4e0;
}

.rem input + label::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background-color: #383838;
  border: 1.5px solid #ffffff;
  border-radius: 5px;
  box-shadow: none;
}

.rem input:checked + label::after {
  content: " ";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: center center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  left: 0px;
  top: 0px;
  text-align: center;
  background-color: transparent;
  font-size: 10px;
  height: 22px;
  width: 22px;
}

.hor {
  width: 43px;
  height: 0px;
  border: 2px solid #59b4e0;
  transform: rotate(90deg);
}

.head {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
}

.head h2 {
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
}

.flexCol{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flexCol p {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 46px;
}
.forgot {
  margin-top: 18px;
  display: flex;
  justify-content: center;
}

.forgot a {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: inline-block;
  color: #59b4e0;
  text-decoration: none;
  cursor: pointer;
}

.tail {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tail h2 {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #59b4e0;
}

.tail p {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-top: 5px;
}

@media screen and (max-width: 720px) {
  .container {
    position: initial;
    padding: initial;
    border-radius: initial;
    background: initial;
    top: initial;
    left: initial;
    transform: initial;
    -ms-transform: initial;
    -webkit-transform: initial;
    display: flex;
    flex-direction: column;
    height: 100dvh;
    justify-content: space-around;
  }
  .InputGroup > label{
    background-color: #1e1e1e;
  }
  .InputGroup > input{
    box-shadow: 0 0 0 50px #1e1e1e inset;
  }
}
@media screen and (max-width: 580px){
  .InputGroup > input{
    width: 400px;
  }
}
@media screen and (max-width: 480px){
  .InputGroup > input{
    width: 350px;
  }
}