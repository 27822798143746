.card {
  width: 280px;
}

.img {
  display: flex;
  height: 165px;
}

.img img {
  flex: 1;
  object-fit: cover;
  border-radius: 8px;
}

.card h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 5px;
}

.card h4 {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 1px;
}

@media screen and (max-width: 720px) {
  .card {
    width: 180px;
  }
  .img {
    height: 126px;
  }
  .card h3 {
    margin-top: 6px;
  }
  .card h4 {
    margin-top: 0px;
  }
}
