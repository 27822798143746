.link {
  text-decoration: none;
  display: block;
  margin: 0 12px;
  width: fit-content;
  border-radius: 20px;
  color: #ffffff;
  margin-bottom: 30px;
  font-weight: 600;
}

.cont {
  display: flex;
  gap: 8px;
  padding: 8px 124px 8px 15px;
}

.active,
.link:hover {
  background: rgba(89, 180, 224, 0.2);
  color: #59b4e0;
}

.active .svg,
.link:hover .svg {
  filter: invert(64%) sepia(61%) saturate(389%) hue-rotate(157deg)
    brightness(91%) contrast(91%);
}

@media screen and (max-width: 856px) {
  .active,
  .link:hover {
    background: initial;
  }
}
